/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  align-items: flex-end;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 1px 4rem 1.4rem rgba(0,0,0,0.26);
  display: flex;
  filter: brightness(1);
  min-height: 100%;
  padding: 8rem;

  & img {
    display: none;
  }
}

.content {
  width: 60%;
}

.title {
  color: var(--white);
  font-size: 4rem!important;
  margin-bottom: 4rem;
  text-transform: uppercase;
}

.subtitle {
  color: var(--white);
  font-size: 2rem;
  font-weight: 400;
}

@media all and (max-width: 1200px) {
  .content {
    width: 100%;
  }
}
