/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  display: flex;
  margin-top: 0;
  margin-bottom: 10rem;

  & th {
    font-size: 1.5rem;
    padding-right: 2rem;
    text-align: left;
  }
  & th + th {
    padding: 1rem 0rem;
    text-align: center;
  }
  & td {
    font-size: 1.6rem;
    padding: 2rem 1rem;
  }
  & tbody td,
  & tbody th {
    border-top: 1px solid var(--seashell);
  }
  & tr {
    padding: 2rem 0;
  }
}

.product-image{
  float: left;
  width: 55%;

    &-items {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &-item > img {
      display: block;
      height: 100%;
      width: 100%;
    }
}

.content {
  box-sizing: content-box;
  float: left;
  padding: 4.5rem 7rem;
  width: 45%;


  &-position {
    position: sticky;
    top: 11rem;
  }
}

.price {
  color: var(--scorpion);
  font-size: 2.5rem;
  font-weight: 600;
}

.actions {
  display: flex;
}

.button {
  margin-right: 1rem;
}

.description {
  color: var(--scorpion);
  margin-top: 5rem;
  max-width: 70rem;
}

@media only screen and (max-width: 1280px) {
  .root {
    flex-direction: column-reverse;
  }
  .content {
    box-sizing: border-box;
    padding: 11rem 2rem;
    position: relative;
    width: 100%;
  }
  .actions {
    flex-direction: row;
  }
  .table {
    margin-bottom: 5rem;
    overflow-x: auto;
  }
  .button {
    margin-bottom: 3rem;
  }
  .description {
    margin-top: 3rem;
  }
  .product-image {
    float: none;
    width: 100%;
  }
  .product-image-item > img {
    padding: 0rem;
  }
}
