/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  margin-bottom: 3.5rem;
}

.link {
  color: var(--mine-shaft);
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.sku {
  background-color: var(--white);
  font-size: 0.9rem;
  font-weight: bold;
  padding: .4rem .6rem;
  position: absolute;
  top: .8rem;
  right: .8rem;
  z-index: 10;
}

.image,
.image-rollover {
  background-color: var(--concrete);
  display: block;
  width: 100%;

  img {
    aspect-ratio: 1/1;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%;
  }
}

.image {
  position: relative;
}

.image-rollover {
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .3s ease;
  z-index: 1;

  .root:hover & {
    opacity: 1;
  }
}

.title {
  font-weight: 700;
  font-size: 1.3rem;
  letter-spacing: .1rem;
  line-height: 1.3rem;
  margin: 2.5rem 0 1rem;
}

.price {
  font-size: 1.1rem;
  min-height: 1.15rem;
  margin: 0.5rem 0;
}

.variants {
  display: flex;
  justify-content: center;
}

.variant {
  border-bottom: 2px solid var(--gallery);
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -.05rem;
  min-width: 3.2rem;
  padding: .6rem .8rem .5rem;
  position: relative;
  margin: 0 .1rem;
  text-align: center;

  &.variant-sold-out {
    border-color: var(--red);

    &::after { background-color: var(--red); }
  }

  &.variant-low {
    border-color: var(--orange);

    &::after { background-color: var(--orange); }
  }

  &.variant-available {
    border-color: var(--green);

    &::after {
      background-color: var(--green);
      color: white;
    }
  }

  &:hover {
    &::after {
      align-items: center;
      content: attr(data-qty);
      display: flex;
      left: 0;
      height: 100%;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
