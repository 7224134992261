/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4rem;
}

.previous-page,
.next-page {
  align-items: center;
  color: var(--black);
  display: flex;
  text-decoration: none;
  width: 25rem;

  &:hover {
  font-weight: 500;
  transition: color 0.4s;

    & .arrow {
      background-color: var(--black);
      fill: var(--white);
      transition: background-color 0.5s;
    }
  }
}

.previous-page {
  flex-direction: row-reverse;
  justify-content: flex-end;

  & .arrow {
    margin-right: 2rem;
  }
}

.next-page {
  justify-content: flex-end;

  &:first-child {
    width: 100%;
  }

  & .arrow {
    margin-left: 2rem;
  }
}

.arrow {
  background-color: var(--concrete);
  border-radius: 50%;
  display: flex;
  fill: currentColor;
  justify-content: center;
  padding: 0.9rem 1.1rem;
}
