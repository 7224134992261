/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  text-align: center;
  margin: 2rem 0 4rem 0;

  td {
    font-size: 2rem;
    padding: 1rem;
  }
}

.input {
  border: 1px solid var(--alto);
  font-size: 1.6rem;
  text-align: center;
  width: 8rem;
}

td.variant {
  font-weight: 400;
  font-size: 1.6rem;

  &.variant-sold-out {
    color: var(--red);
  }

  &.variant-low {
    color: var(--orange);
  }

  &.variant-available {
    color: var(--green);
  }
}

th.variant {
  &.variant-sold-out > span {
    border-bottom: solid 0.2rem var(--red);
    display: inline-block;
    padding-bottom: 0.3rem;
    width: 3rem;
  }

  &.variant-low  > span{
    border-bottom: solid 0.2rem var(--orange);
    display: inline-block;
    padding-bottom: 0.3rem;
    width: 3rem;
  }

  &.variant-available > span {
    border-bottom: solid 0.2rem var(--green);
    display: inline-block;
    padding-bottom: 0.3rem;
    width: 3rem;
  }
}

