/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  --background-color: var(--wildsand);
  --font-color: var(--dove);
  --timeline-color: var(--mercury);

  position: relative;

  &::before {
    background-color: var(--timeline-color);
    content: "";
    display: block;
    height: 100%;
    left: 8.1rem;
    position: absolute;
    width: 0.2rem;
  }
}

.title {
  background-color: var(--background-color);
  position: relative;
}

.date {
  background-color: var(--background-color);
  color: var(--font-color);
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 1.5rem;
  padding: 1.2rem 0;
  position: relative;
  text-transform: uppercase;
}
