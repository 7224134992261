:root {
  --alto: #CFCFCF;
  --athens: #F6F6F7;
  --black: #000;
  --boulder: #888;
  --concrete: #F3F3F3;
  --denim: #1B6BCF;
  --dove: #707070;
  --gallery: #EAEAEA;
  --gray: #919191;
  --green: #56C955;
  --havelock-blue: #4A90E2;
  --mariner: #2273CF;
  --mercury: #E3E3E3;
  --mine-shaft: #333;
  --nepal: #8EA7C6;
  --orange: #F8B861;
  --pacific: #00B0C7;
  --persian: #1E3FBF;
  --red: #D94B4B;
  --scorpion: #666;
  --seashell: #F1F1F1;
  --silver-chalice: #A0A0A0;
  --transparent: transparent;
  --white: #FFF;
  --wildsand: #F5F5F5;
}
