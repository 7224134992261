/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  --font-color: var(--dove);
  --background-color: var(--mercury);
  --icon-color: var(--athens);
  --link-color: var(--denim);
  --link-color-hover: var(--persian);

  color: var(--font-color);
}

.content {
  align-items: center;
  display: flex;
  margin-bottom: 3rem;
}

.hours {
  color: var(--font-color);
  text-align: center;
  width: 5rem;
}

.icon {
  background-color: var(--background-color);
  border-radius: 50%;
  fill: var(--icon-color);
  height: 2rem;
  left: 8.15rem;
  position: absolute;
  transform: translateX(-50%);
  width: 2rem;
}

.card {
  margin-left: 6.5rem;
  padding: 2rem;
  width: 100%;

  &-content > p > a {
    color: var(--link-color);
    text-decoration: none;
  }

  &-content > p > a:hover {
    color: var(--link-color-hover);
  }
}
