/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root{
  background-color: var(--concrete);
  color: var(--silver-chalice);
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 3rem;
  padding: 1.4rem 3rem;

  a {
    color: var(--havelock-blue);

    &:hover {
      color: var(--mariner);
    }
  }
}
