@import "normalize.css";
@import "_colors";

html {
  box-sizing: border-box; /* natural box layout model to all elements */
  font-size: 62.5%; /* for easy rem sizing: 1rem = 10px */
}

/* allow modules to change the box-sizing */
*,
*::after,
*::after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
}

body {
  font-family: proxima-nova, sans-serif;
  font-size: 1.6rem;
}
