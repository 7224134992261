/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  form {
    display: flex;
    flex-direction: column;
  }

  input {
    border : solid 1px var(--alto);
    border-radius: 0.5rem;
    margin-top: 1.3rem;
    padding: 1.2rem;
  }

  input[type="submit"] {
    background-color: var(--havelock-blue);
    border: none;
    border-radius: 3rem;
    color: var(--white);
    cursor: pointer;
    font-size: 1.5-3rem;
    font-weight: 600;
    margin: 2rem auto 0 0;
    padding: 1.2rem 2rem;

    &:hover {
      background-color: var(--mariner);
      transition: all 0.3s ease-in-out;
    }
  }
}
