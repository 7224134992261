/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  display: flex;
  min-height: 100vh;

  & h1 {
    font-size: 2.8rem;
    font-weight: 700;
    margin: 2rem 0 1rem 0;
  }

  &  p {
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 0;
    margin-top: 7rem;
  }
}

.authentification {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
}

.logo img {
  width: 11rem
}

.content {
  width: 60%;
}

.hero {
  width: 70%;

  &-button {
    float: right;
  }
}

@media all and (max-width: 1200px) {
 .hero-button {
    float: none;
    margin-top: 4rem;
  }

  .authentification, .content {
    width: 70%;
  }
}

@media all and (max-width: 850px) {
  .hero{
    width: 50%;
  }
  .content {
    width: 75%;
  }
}

@media all and (max-width: 650px) {
  .hero {
    display: none;
    width: 50%;
  }

  .authentification {
    justify-content: initial;
    width: 100%;
  }

  .content {
    margin-top: 5rem;
    width: 75%;
  }
}
