/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  --font-color: var(--dove);
  --background-icon-color: var(--pacific);
  --icon-color: var(--athens);
  --black: var(--black);

  color: var(--font-color);
  margin: 4rem 0;
}

.content {
  align-items: center;
  display: flex;
  margin-bottom: 3rem;
}

.hours {
  color: var(--font-color);
  text-align: center;
  width: 5rem;
}

.icon {
  background-color: var(--background-icon-color);
  border-radius: 50%;
  fill: var(--icon-color);
  left: 8.15rem;
  padding: 1rem;
  position: absolute;
  transform: translateX(-50%);
}

.card {
  margin-left: 8.5rem;
  width: 100%;
}

.title {
  color: var(--black);
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.person {
  margin: 2rem 0;
}
