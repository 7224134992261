/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  background-color: var(--white);
  border-bottom: 1px solid var(--gallery);
  padding: 0 3rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 11;

  a {
    text-decoration: none;

    &:hover,
    &:focus-visible {
      color: var(--black);
    }
  }

  &.has-logo {
    .brand {
      position: absolute;
      top: 3rem;

      &::after {
        background-color: var(--white);
        border-radius: 50%;
        bottom: 1rem;
        content: "";
        display: block;
        left: 1rem;
        position: absolute;
        right: 1rem;
        top: 1rem;
        z-index: 0;
      }
    }
  }
}

.container {
  align-items: center;
  display: flex;
}

.logo {
  display: block;
  position: relative;
  z-index: 1;
}

.nav {
  margin-left: auto;

  &-items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-item-link {
    border-right: 1px solid var(--gallery);
    color: var(--boulder);
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 2.5rem 4.2rem;

    &:hover,
    &:focus-visible {
      background-color: var(--gallery);
      }
  }

  &-item:nth-child(1) > a:nth-child(1) {
    border-left: 1px solid var(--gallery);
  }
}

.is-active {
  background-color: var(--gallery);

  & > a {
    color: var(--black);
  }
}
