/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  height: 7rem;
}

.items {
  align-items: center;
  display: flex;
  list-style: none;
  padding-left: 0;
}

.item + .item {
  margin-left: 1.2rem;
}

.link-icon {
  background-color: var(--concrete);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 1rem;

  &:hover {
    background-color: var(--nepal);
    transition: background-color 0.6s;
  }
}

.icon {
  background-color: var(--transparent);
  width: 2rem;
}
