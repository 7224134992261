/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 4rem;
  padding: 4rem 2rem;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: var(--white);
  font-size: 4rem;
  margin-left: 14rem;
  text-transform: uppercase;
}

.action {
  font-size: 1.8rem;
  font-weight: 600;

  > a {
    border-radius: 3.3rem;
    border: 0.3rem solid white;
    color: white;
    padding: 1.4rem 4.2rem;
    text-decoration: none;
  }
  > a:hover {
    background-color: var(--white);
    color: var(--black);
    transition: 0.3s;
  }
}

@media all and (max-width: 768px) {
  .root {
    padding: 8rem 2rem;
  }

  .content {
    flex-direction: column;
  }

  .title {
    margin: 2rem 0 4rem 0;
  }
}
