/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  color: var(--black);
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.-item-root + .-item-root::before {
  content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' height='8' width='5'><path d='M5.005 4.011a.767.767 0 0 1-.26.576l-3.71 3.271a.615.615 0 0 1-.811-.924l3.244-2.86a.083.083 0 0 0 0-.125L.224 1.09a.615.615 0 1 1 .81-.924l3.71 3.27a.771.771 0 0 1 .26.576z' fill='%23000000' /></svg>");
  display: inline;
  margin: 0 0.7rem;
}

.-item-root:last-child::before {
  margin-right: 1.2rem;
}

.-item-root:last-child {
  font-weight: normal;
}

.-item-link {
  color: var(--black);
  padding: 0.5rem;
  text-decoration: none;

  &:last-child{
    color: var(--gray11);
    font-weight: var(--medium);
  }

  &:hover {
    color: var(--black);
    text-decoration: none;
  }
}
