/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 3rem;
  color: var(--silver-chalice);
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 1.25;
  padding: 1.5rem 3rem;
  text-decoration: none;

  &:hover {
    color: var(--gray);
    transition: color 0.2s ease-in-out;
  }

  &:active {
    color: var(--gray);
  }
}

/* colors */

.root.color-light {
  background-color: var(--white);
  color: var(--black);

    &:hover {
      background-color: var(--black);
      color: var(--white);
      transition: background-color 0.2s ease-in-out;
    }

    &:active {
      background-color: var(--white);
      color: var(--black);
    }
}

.root.color-light_border {
  border-radius: 3.3rem;
  border: 0.3rem solid white;
  color: var(--white);
  font-size: 1.8rem;
  padding: 1.4rem 4.2rem;
  text-decoration: none;

    &:hover {
      background-color: var(--white);
      color: var(--black);
      transition: background-color 0.2s ease-in-out;
    }

    &:active {
      background-color: var(--white);
      color: var(--black);
    }
}

.root.color-primary {
  background-color: var(--havelock-blue);
  color: var(--white);
  font-weight: bold;

    &:hover {
      background-color: var(--mariner);
      transition: background-color 0.2s ease-in-out;
    }

    &:active {
      background-color: var(--mariner);
      color: var(--white);
    }
}
