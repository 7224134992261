/* Use component-local class names and add them to HTML via #class_for(name) helper */
.root {
  margin-top: 5rem;
}

.logo {
  bottom: 1.2rem;
  height: fit-content;
  position: relative;
  width: 9rem;
}

.nav {
  border-bottom: 1px solid var(--gallery);
  border-top: 1px solid var(--gallery);
  display: flex;
  height: 7rem;
  justify-content: space-around;
  margin-bottom: 3.4rem;

  &-items {
    align-items: center;
    display: flex;
    list-style: none;
    padding-left: 0;
    width: 40rem;
  }

  &-item + &-item {
    margin-left: 1.2rem;
  }

  &-link-icon {
    background-color: var(--concrete);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 1rem;

    &:hover {
      background-color: var(--black);
      transition: background-color 0.6s;

      & .nav-icon {
        fill: var(--white);
      }
    }
  }

  &-icon {
    fill: var(--black);
    width: 2rem;
  }

  &-link:nth-child(3) > a:nth-child(1) {
    margin-right: 0;
  }

  &-link-item {
    color: var(--black);
    font-size: 1.4rem;
    font-weight: 600;
    margin-right: 3rem;
    position: relative;
    text-decoration: none;

    &:before {
      background-color: var(--black);
      bottom: -3px;
      content: "";
      height: 0.2rem;
      left: 0;
      position: absolute;
      transition: 0.2s ease-out;
      width: 0%;
    }
    &:hover:before {
      width: 100%;
    }
  }

  &-heart {
    margin: 0 1.2rem;
    width: 2rem;
  }
}

.baseline {
  align-items: center;
  background: var(--concrete) 0% 0% no-repeat padding-box;
  display: flex;
  font-size: 1.5rem;
  height: 4rem;
  justify-content: center;
}


