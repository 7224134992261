/* Use component-local class names and add them to HTML via #class_for(name) helper */

.root {
  margin: 0 auto;
  max-width: 100rem;

  &.is-full-width {
    max-width: none;
  }

  &.is-full-height {
    display: flex;
    flex-direction: column;
    height: 100vh;

    > :last-child {
      flex-grow: 1;
    }
  }
}
